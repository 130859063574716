import { Button, Drawer, Tabs } from 'antd';
import { ArrowBigRightDash } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { client } from '../../useRequest';
import toastr from "toastr";
import { client2 } from '../../operationalRequest';
import EditSettingComponent from './EditSettingComponent';

type EditSettingProps = {
    data: any
    mutate?: any
}
const EditSetting: React.FC<EditSettingProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [attributes, setAttributes] = useState({} as any);
    const [loading, setLoading] = useState(false)

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const editSetting = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true)
            const response = await client2.put(`api/api-settings/${data?.id}`, {
                setting: values
            })
            if (response.status === 200) {
                mutate({ data: 'data' });
                setOpen(false)
                toastr.success("Setting Updated Successfully");
            }
        } catch (e: any) {
            console.log(e)
        }
        setLoading((curr: any) => false)
    }

    const editAttribute = async (values: any) => {
        try {

            if (loading) return;
            setLoading((curr: any) => true)
            const response = await client2.put(`api/device/${data?.id}`, {
                ...values
            })
            if (response.status === 200) {
                mutate({ data: response.data });
                setOpen(false)
                toastr.success("Device Attribute Updated Successfully");
            }
        } catch (e: any) {
            console.log(e)
        }
        setLoading((curr: any) => false)
    }

// useEffect(() => {

// },[data])
    return (
        <>
            <Button onClick={showDrawer} size='small' className={`bg-blue-500 size-8 rounded-full py-3 flex items-center`} ><ArrowBigRightDash /></Button>
            <Drawer size='large' title="Edit Device" onClose={onClose} open={open}>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: 'Setting',
                            key: '1',
                            children: <EditSettingComponent loading={loading} initialValue={{ ...data, location_id:`${data.deviceable_id}|${data.deviceable_string_type}` }} onSubmit={editSetting} />,
                        },
                        // {
                        //     label: 'Attributes',
                        //     key: '2',
                        //     children: <EditAttributeComponents loading={loading} initialValue={{ attributes: data.attributes }} onSubmit={editAttribute} />,
                        // },

                    ]}
                />
            </Drawer>
        </>
    );
}

export default EditSetting
