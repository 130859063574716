import React from "react";
import Sidebar from "./Sidebar";
import SidebarComponent from "./SidebarComponent";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, MoreVertical, Space } from "lucide-react";
import { Dropdown, MenuProps, Spin, Tag } from "antd";
import { logout } from "../../store/slice/authSlice";
import { Link } from "react-router-dom";
import _ from "lodash";
import { client2 } from "../../operationalRequest";
import { getUserFail } from "../../store/slice/userSlice";
import {
  ClockCircleOutlined,
} from '@ant-design/icons';
const Layout = ({
  children,
  title,
  loading = false,
  breadcrumbs = [],
}: any) => {
  const { user } = useSelector((state: any) => state.user);
  // console.log("User details:", user);
  return (
    <aside className={`h-screen flex`}>
      <SidebarComponent />
      <div className="page_container w-full mx-auto overflow-x-hidden">
        <nav className="flex z-10 justify-between drop-shadow-sm shadow-md sticky top-0 bg-white">
          {breadcrumbs.length > 0 ? (
            <h3 className="my-auto px-10 font-semibold">
              {breadcrumbs.map((item: any, index: number) => (
                <span key={index}>
                  <Link
                    className={`${_.capitalize(item) == title ? "" : "text-gray-500"
                      }`}
                    to={`/${user?.role}/${item}`}
                  >
                    {_.capitalize(item)}
                  </Link>
                  {index < breadcrumbs.length - 1 ? " / " : ""}
                </span>
              ))}
            </h3>
          ) : (
            <h3 className="my-auto px-10 font-semibold">{title}</h3>
          )}
          <div className="flex p-3 space-x-2">
            <div className="w-10 h-10 bg-green-200 rounded-full flex items-center justify-center">
              <span className="text-green-800 font-bold">
                {(user.firstname + " " + user.lastname)
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase())
                  .join("")}
              </span>
            </div>
            <div className="flex items-center overflow-hidden transition-all w-52 mt-1">
              <div className="leading-4 spa">
                <h4 className="font-semibold">
                  {user.firstname + " " + user.lastname}
                </h4>
                <span className="text-xs text-gray-600">{user?.email}</span>
                {/* <p className="text-xs text-gray-600"><Tag icon={<ClockCircleOutlined />}>{user?.details?.timezone || 'UTC'}</Tag></p> */}
              </div>
              <DropDownUser />
            </div>
          </div>
        </nav>
        <div className="px-4 py-4">
          <Spin spinning={loading} tip="Loading..." size="large">
            {children}
          </Spin>
        </div>
      </div>
    </aside>
  );
};

export default Layout;

const DropDownUser = () => {
  const dispatch = useDispatch();
  const handleLogout = async () => {
    const response = await client2.post("api/auth/logout");
    try {
      dispatch(getUserFail({}));
      dispatch(logout());
    } catch (e: any) {
      console.log(e);
    }
  };

  const { user } = useSelector((state: any) => state.user);
  const profileUrl = `/${user?.role}/profile`;

  const items: MenuProps["items"] = [
    {
      label: (
        <Link to={profileUrl} className="flex space-x-4">
          <h6>Profile</h6>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <a className="flex space-x-4" onClick={handleLogout}>
          <h6>Logout</h6> <LogOut />
        </a>
      ),
      key: "1",
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <MoreVertical className="cursor-pointer mt-1" size={20} />
    </Dropdown>
  );
};
