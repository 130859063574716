import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import Datatable from "../../components/datatable/Datatable";
import {
  ArrowBigLeft,
  ArrowBigRightDash,
  BatteryCharging,
  CrossIcon,
  Delete,
  MapPinIcon,
  SidebarCloseIcon,
  Trash2Icon,
  X,
} from "lucide-react";
import { Button, Card, Divider, Modal, Popconfirm, Spin } from "antd";
import toastr from "toastr";
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import { client2 } from "../../operationalRequest";
import AddSetting from "./AddSetting";
import ShowHuaweiPlant from "./huawei/ShowHuaweiPlant";
import ShowGoodwePlant from "./goodwe/ShowGoodwePlant";
import ShowDeddiePlant from "./deddie/ShowDeddiePlant";
import ShowRemoniUnit from "./remoni/ShowRemoniUnit";
import {
  CREATE_API_SETTING,
  UPDATE_API_SETTING,
  DESTROY_API_SETTING,
} from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";
import EditSetting from "./EditSetting";

const Settings = () => {
  const [split, setSplit] = useState(false);
  const [asset, setAsset] = useState(null as any);
  const [mutate, setMutate] = useState({} as any);
  const [locations, setLocations] = useState([] as any);

  const onDelete = async (id: any) => {
    const response = await client2.delete(`api/api-settings/${id}`);
    // if(response.status === 200){
    setMutate({ data: Math.random() });
    toastr.success("Setting Deleted Successfully");
    // }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Created By",
      dataIndex: "created_by.email",
      key: "email",
      sorter: false,
      render: (row: any, record: any) => {
        return record.created_by?.email;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (row: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_API_SETTING}>
              <EditSetting data={record} mutate={setMutate} />
            </PermissionCheck>
            <ShowConnection plant={record} />
            <PermissionCheck permission={DESTROY_API_SETTING}>
              <Popconfirm
                title="Delete the storey"
                description="Are you sure to delete this device?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  className="bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <Trash2Icon />
                </Button>
              </Popconfirm>
            </PermissionCheck>
          </div>
        );
      },
    },
  ];

  //

  return (
    <Layout title={"Settings"}>
      <div className="flex">
        <OperationalDatatable
          mutate={mutate}
          heading="Settings"
          addBody={
            <PermissionCheck permission={CREATE_API_SETTING}>
              <AddSetting mutate={setMutate} />
            </PermissionCheck>
          }
          url="api/api-settings"
          columns={columns}
          filters={{ includeCustomers: true }}
        />
      </div>
    </Layout>
  );
};

export default Settings;

type ShowConnectionProps = {
  plant: any;
};

const ShowConnection: React.FC<ShowConnectionProps> = ({ plant }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Button
        onClick={() => setToggle(!toggle)}
        size="small"
        className="bg-green-400 size-8 rounded-full py-3 flex items-center text-white"
      >
        <BatteryCharging />
      </Button>
      {plant.manufacture_type === 1 && (
        <ShowHuaweiPlant
          connected={toggle}
          id={plant?.id}
          setConnected={setToggle}
        />
      )}
      {plant.manufacture_type === 2 && (
        <ShowGoodwePlant
          connected={toggle}
          id={plant?.id}
          setConnected={setToggle}
        />
      )}
      {plant.manufacture_type === 3 && (
        <ShowDeddiePlant
          connected={toggle}
          id={plant?.id}
          setConnected={setToggle}
        />
      )}
      {plant.manufacture_type === 4 && (
        <ShowRemoniUnit
          connected={toggle}
          id={plant?.id}
          setConnected={setToggle}
        />
      )}
    </>
  );
};
