import { Col, Form, Row, Select } from "antd";
import React, { useState } from 'react';
import LoadingButton from "../../../components/LoadingButton";
import _ from "lodash";
import TextField from "../../../components/formComponents/TextField";
import SelectTable from '../../../components/formComponents/SelectTable';
import { client2 } from "../../../operationalRequest";
type EditUserComponentProps = {
  onSubmit: (values: any, form: any) => void;
  initialValue?: any; // The initial user data
  loading: boolean;
};

const EditUserComponent: React.FC<EditUserComponentProps> = ({ onSubmit, initialValue, loading }) => {
  const [form] = Form.useForm();
  const roles = [
    { id: 2, name: 'support-superadmin' },
    { id: 3, name: 'tenant' },
    { id: 4, name: 'sub-tenant' },
    { id: 5, name: 'support-tenant' },
  ];
  const [selectedRole, setSelectedRole] = useState<string | undefined>(initialValue?.role);
  const [rootUserStatus, setRootUserStatus] = useState<boolean>(initialValue?.is_root_user || false);
  const [isRootUserDisabled, setIsRootUserDisabled] = useState<boolean>(false);  // New state to disable the selection

  const checkCompanyRootUser = async (companyId: any) => {
    try {
      const response = await client2.get(`/api/company/${companyId}/root-user-status`);
      const hasRootUser = response.data.hasRootUser;

      if (!hasRootUser) {
        setRootUserStatus(true);
        setIsRootUserDisabled(true);
        form.setFieldsValue({ is_root_user: true });
      } else {
        setRootUserStatus(false);
        setIsRootUserDisabled(true);
        form.setFieldsValue({ is_root_user: false });
      }
    } catch (error) {
      console.error("Error fetching company root user status:", error);
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 24 }}
      initialValues={{
        ...initialValue,
        is_root_user: initialValue?.is_root_user === true,
      }}
      onFinish={(values: any) => {
        values.role = selectedRole;
        onSubmit(values, form);
      }}
      autoComplete="off"
      layout="vertical"
    >
      <Row>
        <Col span={24}>
          <TextField label="Email" name="email" rules={[{ required: true, message: 'Please input email!' }]} />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: 'Please select a role!' }]}
          >
            <Select
              placeholder="Select Role"
              onChange={value => {
                setSelectedRole(value);
              }}
              value={selectedRole}
            >
              {roles.map(role => (
                <Select.Option key={role.id} value={role.name}>
                  {role.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item
            name="company_id"
            label="Company"
            rules={[{ required: true, message: 'Please select a company!' }]}
          >
            <SelectTable
              table='companies'
              placeholder='Select Company'
              onChange={(val: any) => {
                form.setFieldValue('company_id', val);
                checkCompanyRootUser(val);
              }}
              value={form.getFieldValue('company_id')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <TextField label="Username" name="username" rules={[{ required: false, message: 'Please input username!' }]} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextField label="First Name" name="firstname" rules={[{ required: false, message: 'Please input first name!' }]} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextField label="Last Name" name="lastname" rules={[{ required: false, message: 'Please input last name!' }]} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="is_root_user"
            label="Root User"
          >
            <Select
              placeholder="Select Root User"
              disabled={isRootUserDisabled}
              onChange={value => form.setFieldsValue({ is_root_user: value })}
              value={rootUserStatus}
            >
              <Select.Option value={true}>True</Select.Option>
              <Select.Option value={false}>False</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item>
            <LoadingButton loading={loading} title={_.isEmpty(initialValue) ? "Save" : "Update"} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditUserComponent;
