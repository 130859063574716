import React, { useRef, useEffect, useState } from "react";
import { Alert } from "antd";

interface FilterAlertProps {
  columnFilter: any;
  handleAlertClose: (index: number, filterKey: any) => void;
}
const FilterAlert: React.FC<FilterAlertProps> = ({
  columnFilter,
  handleAlertClose,
}) => {
  const formatKey = (key: string) => {
    return key
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const displayFiter = (columnFilter: any) => {
    return Object.entries(columnFilter).map(([key, value]) => {
      const formattedKey = formatKey(key.replace(/_/g, " "));
      return `${formattedKey}: ${value}`;
    });
  };
  const filteredValues = displayFiter(columnFilter);

  return (
    <div
      style={{
        marginBottom: "10px",
        display: "flex",
        width: "85%",
      }}
    >
      {filteredValues.map((filterValue, index) => (
        <Alert
          key={index}
          message={filterValue}
          type="info"
          closable
          onClose={() =>
            handleAlertClose(index, Object.keys(columnFilter)[index])
          }
          style={{
            width: "fit-content",
            marginRight: "10px",
            borderRadius: "50px",
            padding: "2px 10px",
            backgroundColor: "#fafafa",
            border: "1px solid #f0f0f0",
          }}
        />
      ))}
    </div>
  );
};
export default FilterAlert;
