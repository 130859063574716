// Super Admin Permissions
export const READ_DASHBOARD = 'read-dashboard';
export const READ_PROFILE = 'read-profile';

export const CREATE_SUPPORT_SUPERADMIN = 'create-support-superadmin';
export const READ_SUPPORT_SUPERADMIN = 'read-support-superadmin';
export const UPDATE_SUPPORT_SUPERADMIN = 'update-support-superadmin';
export const DESTROY_SUPPORT_SUPERADMIN = 'destroy-support-superadmin';

export const CREATE_TENANT = 'create-tenant';
export const READ_TENANT = 'read-tenant';
export const UPDATE_TENANT = 'update-tenant';
export const DESTROY_TENANT = 'destroy-tenant';

export const CREATE_SUB_TENANT = 'create-sub-tenant';
export const READ_SUB_TENANT = 'read-sub-tenant';
export const UPDATE_SUB_TENANT = 'update-sub-tenant';
export const DESTROY_SUB_TENANT = 'destroy-sub-tenant';

export const CREATE_USER = 'create-user';
export const READ_USER = 'read-user';
export const UPDATE_USER = 'update-user';
export const DESTROY_USER = 'destroy-user';

export const CREATE_ROLE = 'create-role';
export const READ_ROLE = 'read-role';
export const UPDATE_ROLE = 'update-role';
export const DESTROY_ROLE = 'destroy-role';

export const CREATE_COMPANY = 'create-company';
export const READ_COMPANY = 'read-company';
export const UPDATE_COMPANY = 'update-company';
export const DESTROY_COMPANY = 'destroy-company';

// Tenant Permissions
export const CREATE_SYSTEM = 'create-system';
export const READ_SYSTEM = 'read-system';
export const UPDATE_SYSTEM = 'update-system';
export const DESTROY_SYSTEM = 'destroy-system';

export const CREATE_FORECAST = 'create-forecast';
export const READ_FORECAST = 'read-forecast';
export const UPDATE_FORECAST = 'update-forecast';
export const DESTROY_FORECAST = 'destroy-forecast';

export const READ_NOTIFICATION = 'read-notification';
export const UPDATE_NOTIFICATION = 'update-notification';

export const CREATE_SITE = 'create-site';
export const READ_SITE = 'read-site';
export const UPDATE_SITE = 'update-site';
export const DESTROY_SITE = 'destroy-site';

export const CREATE_BUILDING = 'create-building';
export const READ_BUILDING = 'read-building';
export const UPDATE_BUILDING = 'update-building';
export const DESTROY_BUILDING = 'destroy-building';

export const CREATE_SPACE = 'create-space';
export const READ_SPACE = 'read-space';
export const UPDATE_SPACE = 'update-space';
export const DESTROY_SPACE = 'destroy-space';

export const CREATE_STOREY = 'create-storey';
export const READ_STOREY = 'read-storey';
export const UPDATE_STOREY = 'update-storey';
export const DESTROY_STOREY = 'destroy-storey';

export const CREATE_FLEET = 'create-fleet';
export const READ_FLEET = 'read-fleet';
export const UPDATE_FLEET = 'update-fleet';
export const DESTROY_FLEET = 'destroy-fleet';

export const CREATE_DEVICE = 'create-device';
export const READ_DEVICE = 'read-device';
export const UPDATE_DEVICE = 'update-device';
export const DESTROY_DEVICE = 'destroy-device';

export const READ_REPORT = 'read-report';

export const CREATE_API_SETTING = 'create-api-setting';
export const READ_API_SETTING = 'read-api-setting';
export const UPDATE_API_SETTING = 'update-api-setting';
export const DESTROY_API_SETTING = 'destroy-api-setting';