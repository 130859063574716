import toastr from "toastr";
import { PlusIcon } from 'lucide-react'
import { Modal } from 'antd'
import { useState } from "react";
import { client2 } from "../../../operationalRequest";
import EditUserComponent from "./EditUserComponent";

type AddUserProps = {
  mutate?: any
}
const AddUser: React.FC<AddUserProps> = ({ mutate }) => {
  const [profile, setProfile] = useState({} as any)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const onSubmit = async (values: any, form: any) => {
    const username = values.email.split('@')[0];
    console.log("Submit values: ", values)
    try {
      if (loading) return;
      setLoading(true);
      if (values.role === 'sub-tenant' && !values.company_id) {
        toastr.error("Please select a company for the sub-tenant.");
        return;
      }

      const response = await client2.post('api/users', {
        ...values,
        role: values.role,
        username: username
      });
      // console.log(response);
      mutate({ data: response.data });
      setOpen(false);
      toastr.success("User Created Successfully");
      form.resetFields();
    } catch (e: any) {
      console.log(e);
      toastr.error("An error occurred while creating the user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <a onClick={() => setOpen(true)}>
        <PlusIcon size={30} color='white' strokeWidth={2} className=' rounded-full cursor-pointer bg-gray-500 transition duration-300 ease-in-out hover:bg-gray-400 hover:text-white' />
      </a>
      <Modal
        title={'Add User'}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <EditUserComponent loading={loading} onSubmit={onSubmit} />
      </Modal>
    </>
  )
}

export default AddUser