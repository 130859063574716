import React from "react";
import { Radio } from "antd";
import { NotepadText, ArrowDownNarrowWide } from "lucide-react";

interface FilterPageModeProps {
  size: string;
  handleModeChange: (event: any) => void;
}

const FilterPageMode: React.FC<FilterPageModeProps> = ({
  size,
  handleModeChange,
}) => {
  return (
    <div
      className="flex justify-right"
      style={{
        width: "15%",
        marginBottom: "10px",
      }}
    >
      View:
      <Radio.Group
        value={size}
        onChange={handleModeChange}
        style={{ marginLeft: "5px" }}
      >
        <Radio.Button
          value="pagination"
          title="Pagination"
          style={{ padding: "6px 10px" }}
        >
          <NotepadText style={{ height: "16px", width: "16px" }} />
        </Radio.Button>
        <Radio.Button
          value="loading"
          title="Auto Loading"
          style={{ padding: "6px 10px" }}
        >
          <ArrowDownNarrowWide style={{ height: "16px", width: "16px" }} />
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default FilterPageMode;
