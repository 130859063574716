import React, { useRef, useState } from "react";
import Layout from "../../components/layouts/Layout";
import AddDevice from "./AddDevice";
import EditDevice from "./EditDevice";
import DeleteButton from "../../components/authorizeComponent/DeleteButton";
import {
  CREATE_SYSTEM,
  DESTROY_SYSTEM,
  UPDATE_SYSTEM,
} from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";

import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import type { InputRef, TableColumnType } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
import FilterDatatable from "../../components/datatable/FilterDatatable";
import { SYSTEM_TYPES } from "../../constants/types";

type CreatedBy = {
  email: string;
};

interface DataType {
  key: string;
  name: string;
  type: any;
  created_by: CreatedBy;
}

type DataIndex = keyof DataType;

const Devices = () => {
  const [mutate, setMutate] = useState({} as any);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [columnFilter, setColumnFilter] = useState("");
  const filterValue = `filter_${window.location.pathname}`;

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setColumnFilter((curr: any) => {
      return {
        ...curr,
        [`${dataIndex}`]: selectedKeys[0],
      };
    });

    const existingFilters = localStorage.getItem(filterValue);
    const searchValue = selectedKeys[0];
    let filterObject = existingFilters ? JSON.parse(existingFilters) : {};
    filterObject[dataIndex] = searchValue;
    localStorage.setItem(filterValue, JSON.stringify(filterObject));
  };

  const filterByType = (
    types: { label: string; value: number }[],
    value: string,
    recordValue: number
  ) => {
    const matchedType = types.find((type) =>
      type.label.toLowerCase().includes(value.toLowerCase())
    );
    return matchedType ? matchedType.value === recordValue : false;
  };
  const renderTypeLabel = (
    types: { label: string; value: number }[],
    value: number
  ) => {
    const type = types.find((type) => type.value === value);
    return type ? type.label : "Unknown";
  };

  const formatKey = (dataIndex: string) => {
    return dataIndex
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${formatKey(dataIndex.replace(/_/g, " "))}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === "name") {
        return record.name
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      } else if (dataIndex === "created_by") {
        return record.created_by?.email.toString().includes(value as string);
      } else if (dataIndex === "type") {
        return filterByType(SYSTEM_TYPES, value as string, record.type);
      }

      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) => {
      if (dataIndex === "name") {
        return searchedColumn === dataIndex ? <>{record.name}</> : record.name;
      } else if (dataIndex === "created_by") {
        return searchedColumn === dataIndex ? (
          <>{record.created_by?.email}</>
        ) : (
          record.created_by?.email
        );
      } else if (dataIndex === "type") {
        return renderTypeLabel(SYSTEM_TYPES, record.type);
      }
      return searchedColumn === dataIndex ? <>{text}</> : text;
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Created By",
      dataIndex: "created_by.email",
      key: "email",
      sorter: false,
      render: (row: any, record: any) => {
        return record.created_by?.email;
      },
      ...getColumnSearchProps("created_by"),
    },
    {
      title: "System Type",
      dataIndex: "type",
      key: "type",
      sorter: false,
      render: (row: any, record: any) => {
        const systemType = SYSTEM_TYPES.find(
          (type) => type.value === record.type
        );
        return systemType ? systemType.label : "Unknown";
      },
      ...getColumnSearchProps("type"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (row: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_SYSTEM}>
              <EditDevice data={record} mutate={setMutate} />
            </PermissionCheck>
            <DeleteButton
              model="systems"
              title="system"
              id={record.id}
              setMutate={setMutate}
              permission={DESTROY_SYSTEM}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Layout title={"Systems"}>
      <FilterDatatable
        heading="System"
        url="api/systems"
        columns={columns}
        filters={{ includeCustomers: true }}
        mutate={mutate}
        addBody={
          <PermissionCheck permission={CREATE_SYSTEM}>
            <AddDevice mutate={setMutate} />
          </PermissionCheck>
        }
        columnFilter={columnFilter}
        setColumnFilter={(updatedFilter: any) => {
          setColumnFilter(updatedFilter);
        }}
      />
    </Layout>
  );
};

export default Devices;
