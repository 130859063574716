import { useState } from "react";
import { client2 } from "../../../operationalRequest";
import OperationalDatatable from "../../../components/datatable/OperationalDatatable";
import Layout from "../../../components/layouts/Layout";
import AddCompany from "./AddCompany";
import { Button, Popconfirm } from "antd";
import { CheckCircle, ForwardIcon, Trash2Icon, XCircle } from "lucide-react";
import toastr from "toastr";
import { CREATE_COMPANY, UPDATE_COMPANY, DESTROY_COMPANY } from "../../../utils/permission";
import PermissionCheck from "../../../components/authorizeComponent/PermissionCheck";
import EditCompany from "./EditCompany"
const Companies = () => {
  const [mutate, setMutate] = useState({} as any);
  const onDelete = async (id: any) => {
    try {
      const response = await client2.delete(`api/company/${id}`);
      toastr.success(response.data.message);

      // Trigger re-fetch of the user list
      setMutate({});
    } catch (error) {
      toastr.error("Failed to delete company. Please try again.");
      console.error(error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sorter: true,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      sorter: true,
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (_: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_COMPANY}>
              <EditCompany data={record} mutate={setMutate} />
            </PermissionCheck>
            <PermissionCheck permission={DESTROY_COMPANY}>
              <Popconfirm
                title="Delete the company"
                description="Are you sure to delete this company?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  className="bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <Trash2Icon />
                </Button>
              </Popconfirm>
            </PermissionCheck>

          </div>
        );
      },
    },
  ];

  return (
    <Layout title="Companies">
      <div className="flex">
        <OperationalDatatable
          mutate={mutate}
          heading="Companies"
          addBody={
            <PermissionCheck permission={CREATE_COMPANY}>
              <AddCompany mutate={setMutate} />
            </PermissionCheck>
          }
          url="api/company"
          columns={columns}
          filters={{ includeCustomers: true }}
        />
      </div>
    </Layout>
  );
};

export default Companies;