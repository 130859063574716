import { Button, Drawer, Tabs } from 'antd';
import { ArrowBigRightDash } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { client } from '../../useRequest';
import toastr from "toastr";
import { client2 } from '../../operationalRequest';
import EditSubTenantComponent from './EditSubTenantComponent';
import EditPermissionComponents from './EditPermissionComponent';
import _ from 'lodash';

type EditSubTenantProps = {
    data: any
    mutate?: any
}
const EditSubTenant: React.FC<EditSubTenantProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [attributes, setAttributes] = useState({} as any);
    const [loading, setLoading] = useState(false)

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const editSubtenant = async (values: any) => {
        try {
            if (loading) return;
            setLoading((curr: any) => true)
            const response = await client2.put(`api/sub-tenants/${data?.id}`, {
                subtenant: values
            })
            if (response.status === 200) {
                mutate({ data: 'data' });
                setOpen(false)
                toastr.success("Site Updated Successfully");
            }
        } catch (e: any) {
            console.log(e)
        }
        setLoading((curr: any) => false)
    }

    const editPermission = async (values: any) => {
        try {

            if (loading) return;
            setLoading((curr: any) => true)
            const response = await client2.put(`api/sub-tenant/permission/${data?.id}`, {
                ...values
            })
            if (response.status === 200) {
                mutate({ data: response.data });
                setOpen(false)
                toastr.success("Permissions Updated Successfully");
            }
        } catch (e: any) {
            console.log(e)
        }
        setLoading((curr: any) => false)
    }


    return (
        <>
            <Button onClick={showDrawer} size='small' className={`bg-blue-500 size-8 rounded-full py-3 flex items-center`} ><ArrowBigRightDash /></Button>
            <Drawer size='large' title="Edit Sub Tenant" onClose={onClose} open={open}>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: 'Sub Tenant',
                            key: '1',
                            children: <EditSubTenantComponent loading={loading} initialValue={{ ...data }} onSubmit={editSubtenant} />,
                        },
                        {
                            label: 'Permissions',
                            key: '2',
                            children: <EditPermissionComponents loading={loading} initialValue={{ permissions: _.map(data.permissions, 'name'), role: data.role }} onSubmit={editPermission} />,
                        },

                    ]}
                />
            </Drawer>
        </>
    );
}

export default EditSubTenant
