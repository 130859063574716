import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form as CustomForm,
  type FormProps,
  Input,
  Select,
  Spin,
} from "antd";
import { client } from "../../useRequest";
import { useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import LoadingButton from "../../components/LoadingButton";
import _ from "lodash";
import SelectTable from "../../components/formComponents/SelectTable";
import SelectDeviceLocation from "../../components/formComponents/SelectDeviceLocation";
import CustomSelectOptions from "../../components/formComponents/CustomSelectOptions";

type EditSettingComponentProps = {
  onSubmit: any;
  initialValue?: any;
  loading: boolean;
};
const EditSettingComponent: React.FC<EditSettingComponentProps> = ({
  onSubmit,
  initialValue,
  loading,
}) => {
  const [form] = CustomForm.useForm();
  const [type, setType] = useState("");
  const { role } = useSelector((state: any) => state.role);

  return (
    <CustomForm
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      initialValues={initialValue}
      onFinish={(values: any) => onSubmit(values, form)}
      autoComplete="off"
      layout="vertical"
    >
      <CustomForm.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input name!" }]}
      >
        <Input />
      </CustomForm.Item>

      <CustomForm.Item
        label="Manufacture"
        name="manufacture_type"
        rules={[{ required: true, message: "Please input type!" }]}
      >
        <CustomSelectOptions
          options={[
            { value: "1", label: "Huawei" },
            { value: "2", label: "GoodWe" },
            { value: "3", label: "DEDDIE" },
            { value: "4", label: "ReMoni" },
          ]}
          onChange={(val: any) => form.setFieldValue("manufacture_type", val)}
        />
      </CustomForm.Item>

      <CustomForm.Item
        label="Api Type"
        name="type"
        rules={[{ required: true, message: "Please input type!" }]}
      >
        <CustomSelectOptions
          options={[
            { value: "password", label: "Password Auth" },
            { value: "key", label: "Key" },
          ]}
          onChange={(val: any) => {
            form.setFieldValue("type", val);
            setType(val);
          }}
        />
      </CustomForm.Item>

      {type == "password" && (
        <>
          <CustomForm.Item
            label="User Name"
            name={["config", "username"]}
            rules={[{ required: true, message: "Please input username!" }]}
          >
            <Input />
          </CustomForm.Item>

          <CustomForm.Item
            label="Password"
            name={["config", "password"]}
            rules={[{ required: true, message: "Please input password!" }]}
          >
            <Input type="password" />
          </CustomForm.Item>
        </>
      )}

      {type == "key" && (
        <>
          <CustomForm.Item
            label="Key"
            name={["config", "token"]}
            rules={[{ required: true, message: "Please input key!" }]}
          >
            <Input type="password" />
          </CustomForm.Item>
          <CustomForm.Item
            label="Tax Number"
            name={["config", "taxNumber"]}
            rules={[{ required: true, message: "Please input tax Number!" }]}
          >
            <Input type="password" />
          </CustomForm.Item>
        </>
      )}

      {!role.role.includes('sub-tenant') && (
        <CustomForm.Item
          label="Assign To"
          name="assign_to"
          rules={[
            { required: false, message: "Please select the assignee!" },
          ]}
        >
          <SelectTable
            placeholder="Select User"
            onChange={(val: any) => form.setFieldValue("assign_to", val)}
            value={form.getFieldValue("assign_to")}
            table="tenantUser"
          />
        </CustomForm.Item>
      )}

      <CustomForm.Item>
        <LoadingButton
          loading={loading}
          title={_.isEmpty(initialValue) ? "Save" : "Update"}
        />
      </CustomForm.Item>
    </CustomForm>
  );
};

export default EditSettingComponent;
