import { useState } from "react";
import { client2 } from "../../../operationalRequest";
import OperationalDatatable from "../../../components/datatable/OperationalDatatable";
import Layout from "../../../components/layouts/Layout";
import AddUser from "./AddUser";
import { Button, Popconfirm } from "antd";
import { CheckCircle, ForwardIcon, Trash2Icon, XCircle } from "lucide-react";
import toastr from "toastr";
import {
  CREATE_USER,
  UPDATE_USER,
  DESTROY_USER
} from "../../../utils/permission";
import PermissionCheck from "../../../components/authorizeComponent/PermissionCheck";
import EditUser from "./EditUser";
const Users = () => {
  const [mutate, setMutate] = useState({} as any);
  const onDelete = async (id: any) => {
    try {
      const response = await client2.delete(`api/users/${id}`);
      toastr.success(response.data.message);

      // Trigger re-fetch of the user list
      setMutate({});
    } catch (error) {
      toastr.error("Failed to delete user. Please try again.");
      console.error(error);
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
      sorter: true
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
      sorter: true
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      sorter: true
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (_: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_USER}>
              <EditUser data={record} mutate={setMutate} />
            </PermissionCheck>
            <PermissionCheck permission={DESTROY_USER}>
              <Popconfirm
                title="Delete the user"
                description="Are you sure to delete this user?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  className="bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <Trash2Icon />
                </Button>
              </Popconfirm>
            </PermissionCheck>
          </div>
        );
      }
    }
  ];

  return (
    <Layout title="Users">
      <div className="flex">
        <OperationalDatatable
          mutate={mutate}
          heading="Users"
          addBody={
            <PermissionCheck permission={CREATE_USER}>
              <AddUser mutate={setMutate} />
            </PermissionCheck>
          }
          url="api/users"
          columns={columns}
          filters={{ includeCustomers: true }}
        />
      </div>
    </Layout>
  );
};

export default Users;
