import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Button, Col, Row, DatePicker, Checkbox, DatePickerProps } from "antd";
import { client2 } from "../../operationalRequest";
import ReactApexChart from "react-apexcharts";
import SelectTable from "../../components/formComponents/SelectTable";
import _ from "lodash";
import { Dayjs } from "dayjs";

const Reports2 = () => {
  const [date, setDate] = useState<[string, string] | null>(null);
  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [systemType, setSystemType] = useState<any>({});
  const { RangePicker } = DatePicker;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await client2.post(`api/report-overview2`, {
        systemType,
        date,
      });
      if (response.status === 200) {
        const rawData = response.data.data;
        // Extract all unique timestamps from the data
        const allTimestamps = _.chain(rawData)
          .flatMap("data")
          .map("x")
          .uniq()
          .sort()
          .value();
        // Combine data by filling missing timestamps with 0
        const combinedData = _.chain(rawData)
          .groupBy("name")
          .map((items, name) => {
            const seriesData = allTimestamps.map((timestamp) => {
              const point = items.reduce(
                (acc, series) => {
                  const foundPoint = series.data.find(
                    (p: any) => p.x === timestamp
                  );
                  if (foundPoint) {
                    acc.y += parseFloat(foundPoint.y);
                  }
                  return acc;
                },
                { x: timestamp, y: 0 }
              );
              return point;
            });
            return { name: _.startCase(_.toLower(name)), data: seriesData };
          })
          .value();
        console.log(combinedData);
        setData(combinedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const options = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: true,
      },
    },
    xaxis: {
      type: "category",
      labels: {
        formatter: function (val: any) {
          if (typeof val === "string" && val.includes(":")) {
            const [hours, minutes] = val.split(":");
            return `${hours}:${minutes}`;
          }
          return val;
        },
      },
      title: {
        text: "Time",
      },
    },
    yaxis: {
      title: {
        text: "kW",
      },
      labels: {
        formatter: function (val: any) {
          return !!val ? val.toFixed(2) : 0;
        },
      },
    },

    stroke: {
      width: 2,
    },
    markers: {
      size: 0,
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val: any, { seriesIndex }: any) => {
          // Display tooltip differently for SOC (index of SOC should be adjusted based on the order in the series array)
          if (data[seriesIndex]?.name.toLowerCase() === "soc") {
            return `${val.toFixed(2)}% SoC`; // Customize SoC tooltip
          }
          return `${val.toFixed(2)} kWh`; // Customize other series tooltips
        },
      },
    },
  } as any;

  const getYearMonth = (date: Dayjs) => date.year();
  const disabled3DaysDate: DatePickerProps["disabledDate"] = (
    current,
    { from, type }
  ) => {
    if (from) {
      const minDate = from.add(0, "days");
      const maxDate = from.add(2, "days");

      switch (type) {
        case "year":
          return (
            current.year() < minDate.year() || current.year() > maxDate.year()
          );

        case "month":
          return (
            getYearMonth(current) < getYearMonth(minDate) ||
            getYearMonth(current) > getYearMonth(maxDate)
          );

        case "week":
          return Math.abs(current.diff(from, "week")) > 0; // Allow only the same week

        // case 'quarter':
        //   return (
        //     current.quarter() < minDate.quarter() ||
        //     current.quarter() > maxDate.quarter() ||
        //     current.year() !== minDate.year() // Restrict to the same year
        //   );

        default:
          return Math.abs(current.diff(from, "days")) >= 3;
      }
    }

    return false;
  };

  return (
    <Layout title="Report Overview" loading={loading}>
      <div className="px-4 py-2 border rounded-md shadow-md mb-5 w-full">
        <Row className="w-full space-x-3">
          <Col md={6}>
            <SelectTable
              value={systemType?.site}
              placeholder="Select Site"
              table="sites"
              onChange={(val: any) => {
                setSystemType((curr: any) => ({
                  ...curr,
                  site: val,
                  system: undefined,
                  devices: undefined,
                }));
              }}
            />
          </Col>
          {!!systemType?.site && (
            <Col md={6}>
              <SelectTable
                value={systemType?.system}
                filter={[{ table: "system_id", value: systemType?.site }]}
                placeholder="Select System"
                table="systems"
                onChange={(val: any) => {
                  setSystemType((curr: any) => ({ ...curr, system: val }));
                }}
              />
            </Col>
          )}
          {!!systemType?.system && (
            <Col md={6}>
              <SelectTable
                value={systemType?.devices}
                placeholder="Select Devices"
                table="system_devices"
                multiple
                filter={[{ table: "system_id", value: systemType?.system }]}
                onChange={(val: any) => {
                  setSystemType((curr: any) => ({ ...curr, devices: val }));
                }}
              />
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          <Col md={6}>
            <RangePicker
              disabledDate={disabled3DaysDate}
              format="YYYY-MM-DD"
              onChange={(dates, dateStrings) => {
                const formattedDates = dates?.map((date) =>
                  date?.format("YYYY-MM-DD")
                ) as [string, string];
                // Handle selected date range
                setDate(formattedDates);
              }}
              // picker={['month', 'year', 'week', 'quarter'].includes(systemType.dateType) ? systemType.dateType : undefined}
            />
          </Col>
          <Col className="space-x-2 mt-1">
            {/* <Button onClick={() => setSystemType((curr: any) => {
              return { ...curr, dateType: 'day' }
            })} size='small' className={`${systemType.dateType === 'day' ? 'border-blue-500' : ''}`}  >Day</Button>
            <Button onClick={() => setSystemType((curr: any) => {
              return { ...curr, dateType: 'week' }
            })} size='small' className={`${systemType.dateType === 'week' ? 'border-blue-500' : ''}`} >Week</Button>
            <Button onClick={() => setSystemType((curr: any) => {
              return { ...curr, dateType: 'month' }
            })} size='small' className={`${systemType.dateType === 'month' ? 'border-blue-500' : ''}`} >Month</Button> */}
            {/* <Button onClick={() => setSystemType((curr:any) => {
              return {...curr, dateType: 'quarter'}
             })} size='small' className={`${systemType.dateType === 'quarter' ? 'border-blue-500': ''}`} >Quarter</Button> */}

            {/* <Button onClick={() => setSystemType((curr: any) => {
              return { ...curr, dateType: 'year' }
            })} size='small' className={`${systemType.dateType === 'year' ? 'border-blue-500' : ''}`} >Year</Button> */}
          </Col>
        </Row>

        <Row className="mt-2">
          <Col span={3}>
            <Button onClick={() => fetchData()}>Generate</Button>
          </Col>
        </Row>
      </div>
      <ReactApexChart
        options={options}
        series={data}
        type="area"
        height={350}
      />
    </Layout>
  );
};

export default Reports2;
