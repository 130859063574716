import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { client2 } from '../../operationalRequest';
import { Badge, DatePicker, DatePickerProps } from 'antd';
import dayjs from 'dayjs';

const LoadReport = () => {
    const [date, setDate] = useState('');
    const [dateType, setDateType] = useState('day');
    const [data, setData] = useState({
        series: [],
        options: {
            chart: {
                type: 'line',
                height: 350,
            },
            stroke: {
                curve: 'smooth',
                width: [2, 2],
            },
            xaxis: {
                categories: [],
                title: {
                    text: dateType === 'day' ?'Hour of Day': 'Dates',
                },
            },
            yaxis: {
                title: {
                    text: 'Load (kWh)',
                },
                labels: {
                    formatter: (value: any) =>
                        parseFloat(value).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                },
            },
            legend: {
                position: 'top',
            },
            colors: [],
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                y: {
                    formatter: (value: any) =>
                        parseFloat(value).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                },
            },
        },
    } as any);

    const fetchData = async (date: any) => {
        const response = await client2.get(
            `api/dashboard/load-data2?date=${date}&&dateType=${dateType}`
        );

        let loadAxis: string[] = [];
        let seriesData = [] as any;
        let text = 'Hour of Day'
        if (dateType === 'day') {
            loadAxis = Array.from({ length: 24 }, (_, i) => `${i}:00`);
             seriesData = Object.entries(response?.data?.data ?? {}).map(
                ([key, values]: [string, any]) => ({
                    name: key,
                    data: Object.values(values)
                })
            );
        } else if (dateType === 'month' || dateType === 'week') {
            loadAxis = [
                ...new Set(
                  Object.values(response.data.data)
                    .flatMap((device: any) => Object.keys(device))
                )
              ].map((date: string) => dayjs(date).format('MMM DD')); 
            seriesData = Object.entries(response?.data?.data ?? {}).map(
                ([key, values]: [string, any]) => ({
                  name: key,
                  // Get all the values for each date
                  data: Object.values(values),
                })
              );
              text = "Dates"
        }else if(dateType === "year"){
            loadAxis = [
                ...new Set(
                  Object.values(response.data.data)
                    .flatMap((device: any) => Object.keys(device))
                )
              ].map((date: string) => dayjs(date).format('MMM')); 
            seriesData = Object.entries(response?.data?.data ?? {}).map(
                ([key, values]: [string, any]) => ({
                  name: key,
                  // Get all the values for each date
                  data: Object.values(values),
                })
              );
              text = 'Months';
        }else{
            loadAxis = ['Total']
            seriesData = Object.entries(response?.data?.data ?? {}).map(
                ([key, values]: [string, any]) => ({
                  name: key,
                  // Get all the values for each date
                  data: [values['total_production']],
                })
              );
              text = "";
        }

        
        
        console.log('loadax', seriesData)

        const colors = [
            '#FFBF00',
            '#008FFB',
            '#00E396',
            '#FF4560',
            '#775DD0',
            '#a8324e',
            '#a85532',
            '#CCCCFF',
            '#808000',
        ];

        setData((prevState: any) => ({
            ...prevState,
            series: seriesData,
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: loadAxis,
                    title: {
                        text: text,
                    },
                },
                colors: colors.slice(0, seriesData.length),
            },
            chart: {
                type: dateType === 'total' ?'bar' : 'line',
                height: 350,
            },
        }));
    };

    const onChange: DatePickerProps['onChange'] = (date: any, dateString: any) => {
        setDate(dateString);
    };

    useEffect(() => {
        fetchData(date);
    }, [date, dateType]);

    return (
        <>
            <div className="flex justify-start space-x-3">
                <div>
                    {dateType === 'day' && (
                        <DatePicker
                            defaultValue={dayjs().subtract(1, 'day')}
                            onChange={onChange}
                        />
                    )}
                    {dateType === 'week' && (
                        <DatePicker onChange={onChange} picker="week" />
                    )}
                    {dateType === 'month' && (
                        <DatePicker onChange={onChange} picker="month" />
                    )}
                    {dateType === 'year' && (
                        <DatePicker onChange={onChange} picker="year" />
                    )}
                </div>
                <div className="space-x-3 flex justify-start mt-1">
                    <div
                        onClick={() => {
                            setDateType('day');
                            setDate('');
                        }}
                        className="cursor-pointer"
                    >
                        <Badge color={dateType === 'day' ? 'grey' : 'red'} count={'Day'} />
                    </div>
                    <div
                        onClick={() => {
                            setDateType('week');
                            setDate('');
                        }}
                        className="cursor-pointer"
                    >
                        <Badge color={dateType === 'week' ? 'grey' : 'red'} count={'Week'} />
                    </div>
                    <div
                        onClick={() => {
                            setDateType('month');
                            setDate('');
                        }}
                        className="cursor-pointer"
                    >
                        <Badge color={dateType === 'month' ? 'grey' : 'red'} count={'Month'} />
                    </div>
                    <div
                        onClick={() => {
                            setDateType('year');
                            setDate('');
                        }}
                        className="cursor-pointer"
                    >
                        <Badge color={dateType === 'year' ? 'grey' : 'red'} count={'Year'} />
                    </div>
                    <div
                        onClick={() => {
                            setDateType('total');
                            setDate('');
                        }}
                        className="cursor-pointer"
                    >
                        <Badge color={dateType === 'total' ? 'grey' : 'red'} count={'Total'} />
                    </div>
                </div>
            </div>
            <Chart options={data.options as any} series={data.series} type={dateType === 'total' ? 'bar': 'line'} height={350} />
        </>
    );
};

export default LoadReport;
