import { Col, Form, Input, Row } from "antd";
import _ from "lodash";
import TextField from "../../components/formComponents/TextField";
import LoadingButton from "../../components/LoadingButton";

type EditSubTenantComponentProps = {
  onSubmit: any,
  initialValue?: any,
  loading: boolean,
}
const EditSubTenantComponent: React.FC<EditSubTenantComponentProps> = ({ onSubmit, initialValue, loading }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 24 }}
      initialValues={initialValue}
      onFinish={(values: any) => onSubmit(values, form)}
      autoComplete="off"
      layout="vertical"
    >
      <Row>
        <Col span="24">
          <TextField label="Email" name={"email"} rules={[{ required: true, message: 'Please input email!' }]} />
        </Col>
      </Row>

      <Form.Item>
        <LoadingButton loading={loading} title={_.isEmpty(initialValue) ? "Save" : "Update"} />
      </Form.Item>
    </Form>
  )
}

export default EditSubTenantComponent