import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import LoadingButton from "../../components/LoadingButton";
import moment from "moment-timezone";
type ChangeTimezoneComponentProps = {
  onSubmit: (values: any, form: any) => void;
  initialValue?: any;
  loading: boolean;
};
const ChangeTimezoneComponent: React.FC<ChangeTimezoneComponentProps> = ({
  onSubmit,
  initialValue,
  loading,
}) => {
  const [form] = Form.useForm();
  const timezones = moment.tz.names();
  return (
    <>
      <Form
        form={form}
        name="basic"
        initialValues={initialValue}
        onFinish={(values: any) => onSubmit(values, form)}
        autoComplete="off"
        layout="vertical"
        style={{ width: "300px" }}
      >
        <Form.Item
          label="Timezone"
          name="timezone"
          rules={[{ required: true, message: "Please select your timezone!" }]}
        >
          <Select
            showSearch
            placeholder="Select your timezone"
            style={{ width: "100%" }}
            defaultValue={initialValue?.details?.timezone}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children
                ? option.children.toString().toLowerCase().includes(input.toLowerCase())
                : false
            }
          >
            {timezones.map((tz) => (
              <Select.Option key={tz} value={tz}>
                {tz}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <LoadingButton loading={loading} title={"Update Timezone"} />
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangeTimezoneComponent;