export const MANUFACTURE_TYPES = [
    { label: "Huawei", value: 1 },
    { label: "GoodWe", value: 2 },
    { label: "DEDDIE", value: 3 },
    { label: "ReMoni", value: 4 },
    { label: "Custom", value: -1 },
];

export const DEVICE_TYPES = [
    { label: "Inverter", value: 10 },
    { label: "Grid Electrical Meter", value: 20 },
    { label: "Electrical Meter", value: 30 },
    { label: "Battery", value: 40 },
    { label: "Load", value: 50 },
    { label: "Sensor", value: 60 },
    { label: "Unknown", value: -1 },
];

export const SYSTEM_TYPES = [
    { label: "Energy System", value: 1 },
    { label: "PV Energy System", value: 2 },
    { label: "Energy Storage", value: 3 },
    { label: "Energy Consumption", value: 4 },
    { label: "Grid Point", value: 5 },
    { label: "Unknown", value: -1 },
];

