import React, { useState } from "react";
import { PlusIcon } from "lucide-react";
import { Modal } from "antd";
import EditCompanyComponent from "./EditCompanyComponent";
import { client2 } from "../../../operationalRequest";
import toastr from "toastr";

type AddCompanyProps = {
  mutate?: any;
};

const AddCompany: React.FC<AddCompanyProps> = ({ mutate }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onSubmit = async (values: any, form: any) => {
    try {
      if (loading) return;
      const response = await client2.post("api/company", {
        ...values,
      });
      mutate({ data: response.data });
      setOpen(false);
      toastr.success("Company Created Successfully");
      form.resetFields();
    } catch (e: any) {
      console.log(e);
    }
    setLoading((curr: any) => false);
  };

  return (
    <>
      <a onClick={() => setOpen(true)}>
        <PlusIcon
          size={30}
          color="white"
          strokeWidth={2}
          className=" rounded-full cursor-pointer bg-gray-500 transition duration-300 ease-in-out hover:bg-gray-400 hover:text-white"
        />
      </a>
      <Modal
        title={"Add Company"}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        okButtonProps={{ style: { display: "none" } }}
      >
        <EditCompanyComponent loading={loading} onSubmit={onSubmit} />
      </Modal>
    </>
  );
};

export default AddCompany;