import React, { useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Divider, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import ChangePasswordComponent from "./ChangePasswordComponent";
import ChangeTimezoneComponent from "./ChangeTimezoneComponent";
import { client2 } from "../../operationalRequest";

const Profile = () => {
  const { user } = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = async (values: any, form: any) => {
    try {
      if (loading) return;
      setLoading(true);
      const email = user?.email;
      if (values.password == values.confirmPassword) {
        const response = await client2.post(`api/auth/change-password`, {
          email,
          currentPassword: values.currentPassword,
          password: values.password,
          confirmPassword: values.confirmPassword,
        });
        if (response.status === 200) {
          toastr.success("Password changed successfully!");
          form.resetFields();
        }
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleTimezoneChange = async (values: any, form: any) => {
    try {
      if (loading) return;
      setLoading(true);
      const email = user?.email;
      const response = await client2.patch(`api/users/${user.id}`, {
        details: {
          timezone: values.timezone,
        },
        email,
        role: user.role,
        username: user.username
      });
      //console.log("API Response: ", response);
      if (response.status === 200) {
        toastr.success("Timezone changed successfully!");
        form.resetFields();
        // Optionally update the user in state if needed
        // dispatch(updateUser({...user, timezone: values.timezone}));
      }
    } catch (e: any) {
      console.log("Error occurred: ", e.response ? e.response.data : e.message);
      toastr.error("Failed to change timezone.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout title={"Profile"}>
      <div className="px-8">
        <div>
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            icon={<UserOutlined />}
          />
          <p>{user.firstname + " " + user.lastname}</p>
          <p>{user?.email}</p>
          <p>Time zone: {user?.details?.timezone || "No timezone set"}</p>
        </div>
        <Divider orientation="left">Change Password</Divider>
        <ChangePasswordComponent
          loading={loading}
          onSubmit={handlePasswordChange}
        />
        <Divider orientation="left">Change Timezone</Divider>
        <ChangeTimezoneComponent
          loading={loading}
          onSubmit={handleTimezoneChange}
          initialValue={user}
        />
      </div>
    </Layout>
  );
};
export default Profile;