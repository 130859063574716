import React from "react";
import { useSelector } from "react-redux";

type PermissionCheckProps = {
  permission: string;
  children: React.ReactNode; // Define the type for children
};

const PermissionCheck: React.FC<PermissionCheckProps> = ({
  children,
  permission,
}) => {
  const { role } = useSelector((state: any) => state.role);
  // console.log(role, permission);
  return (
    <>
      {(role?.role?.includes("tenant") ||
        role?.permissions?.includes(permission)) &&
        children}
    </>
  );
};

export default PermissionCheck;
