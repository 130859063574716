import { Button, Col, Form, message, Row, Steps, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import TextField from '../../components/formComponents/TextField';
import LoadingButton from '../../components/LoadingButton';
import { client2 } from '../../operationalRequest';
import { useLocation, useNavigate } from 'react-router-dom';
import toastr from "toastr";


const Onboarding = () => {
    const [current, setCurrent] = useState(0);
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const paramValue = queryParams.get('token')
    const [steps, setSteps] = useState([] as any);
    const step = [
        {
            title: 'User',
            content: (
                <Row>
                    <Col span={24}>
                        <TextField
                            label="Firstname"
                            name={['user', 'firstname']}
                            rules={[{ required: true, message: 'Please input Firstname!' }]}
                        />
                    </Col>
                    <Col span={24}>
                        <TextField
                            label="Lastname"
                            name={['user', 'lastname']}
                            rules={[{ required: true, message: 'Please input Lastname!' }]}
                        />
                    </Col>
                    <Col span={24}>
                        <TextField
                            label="Username"
                            name={['user', 'username']}
                            rules={[{ required: true, message: 'Please input username!' }]}
                        />
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Create Password',
            content: (
                <Row>
                    <Col span={24}>
                        <TextField
                            label="Password"
                            name={['password', 'password']}
                            rules={[
                                { required: true, message: 'Please input Password!' },
                                // Add custom validation for password strength (optional)
                            ]}
                            type="password"
                        />
                    </Col>
                    <Col span={24}>
                        <TextField
                            label="Confirm Password"
                            name={['password', 'confirm_password']}
                            rules={[
                                { required: true, message: 'Please input Confirm Password!' },
                                ({ getFieldValue }: any) => ({
                                    validator: (rule: any, value: any) => {
                                        if (value !== getFieldValue(['password', 'password'])) {
                                            return Promise.reject('Passwords do not match!');
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            type="password"
                        />
                    </Col>
                </Row>
            ),
        },
    ];

    const tenant = [{
        title: 'Company',
        content: (
            <Row>
                <Col span={24}>
                    <TextField
                        label="Company Name"
                        name={['company', 'name']}
                        rules={[{ required: true, message: 'Please input company name!' }]}
                    />
                </Col>
                <Col span={24}>
                    <TextField
                        label="Phone"
                        name={['company', 'phone']}
                        rules={[{ required: false, message: 'Please input phone!' }]}
                    />
                </Col>
                <Col span={24}>
                    <TextField
                        label="Address"
                        name={['company', 'address']}
                        rules={[{ required: false, message: 'Please input address!' }]}
                    />
                </Col>
            </Row>
        ),
    }]
    console.log(steps,'steps')
    const items = steps.map((item: any) => ({ key: item.title, title: item.title }));

    const onSubmit = async (values: any) => {
        try {
            const response = await client2.post(`/api/onboarding?token=${paramValue}`, { ...values })
            if (current == steps.length -1) {
                toastr.success("Onboarding Complete")
                navigate("/")
            }
            if (response.status === 200) {
                return true
            } else {
                return false
            }
        } catch (e: any) {
            message.error('Somthing is wrong');
        }
    }

    const checkToken = async () => {
        try {
            const response = await client2.get(`/api/onboarding?token=${paramValue}`)
            if(response.data.success){
                if(response.data.data.type === 'onboarding-tenant-request'){
                    setSteps(tenant.concat(step))
                }else if(response.data.data.type === 'onboarding-sub-tenant-request'){
                    setSteps(step)
                }
            }

            if (!response.data.success) {
                toastr.error(response.data.message)
                navigate("/")
            }
        } catch (e: any) {
            navigate("/")
        }
    }

    const contentStyle: React.CSSProperties = {
        lineHeight: '360px',
        textAlign: 'center',
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px solid ${token.colorBorder}`,
        marginTop: 16,
        padding: 20,
    };

    const next = async () => {
        try {
            // Validate form fields based on the current step
            await form.validateFields();
            if (await onSubmit(form.getFieldsValue())) {
                setCurrent((curr: any) => current + 1);
            }

        } catch (errorInfo) {
            console.error('Form validation failed:', errorInfo);
            message.error('Please fix the highlighted fields!');
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    useEffect(() => {
        checkToken()
    }, [])

    return (
        <section className="bg-gray-50 min-h-screen flex items-center justify-center">
            <div className="bg-gray-100 rounded-2xl shadow-lg max-w-3xl p-5 items-center">
                <div className='flex justify-center h-8 mb-5'>
                    <img src="/logo_notext.png" alt="Apollo Logo" />
                </div>
                <div>
                    <Steps current={current} items={items} />
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{ span: 24 }}
                        initialValues={{}}
                        onFinish={onSubmit}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <div style={contentStyle}>{steps[current]?.content}</div>
                        <div style={{ marginTop: 24 }}>
                            {current < steps.length - 1 && (
                                <Button type="primary" onClick={next}>
                                    Next
                                </Button>
                            )}
                            {current === steps.length - 1 && (
                                <LoadingButton loading={false} title='Submit' />
                            )}
                            {current > 0 && (
                                <Button style={{ margin: '0 8px' }} onClick={prev}>
                                    Previous
                                </Button>
                            )}
                        </div>
                    </Form>
                </div>
            </div>
        </section>
    );
};

export default Onboarding;
