import useSWR from 'swr';
import axios from 'axios';
import toastr from 'toastr';
import { logout } from './store/slice/authSlice';
import store from './store/store';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const client2 = axios.create({
  baseURL: process.env.REACT_APP_OPERATIONAL_URL, // Ensure this is correctly set in your .env
  timeout: 60000,
  headers,
});

// Interceptors for handling responses and requests
client2.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // Handle unauthorized access
      if (error.response.status === 401) {
        store.dispatch(logout());
      }
      // Handle validation errors
      if (error.response.status === 422) {
        Object.values(error.response.data).forEach(item => {
          toastr.error(Object.values(item).join(', '), 'Validation Error');
        });
      } else {
        toastr.error(error.response.data.message || error.message);
      }
    } else {
      toastr.error('Network error or server not reachable.');
    }
    return Promise.reject(error.response);
  }
);

// Interceptor to attach the authorization token
client2.interceptors.request.use(
  config => {
    const accessToken = store.getState().auth.token?.token;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  err => Promise.reject(err)
);

// Function to upload media files
const uploadMedia = async (files, url, fields = {}) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files[]', file);
  });

  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const response = await client2.post(url, formData);
  return response.data; // Return response data directly for easier handling
};

// Function to download a blob file
const downloadBlobFile = async (apiUrl, fileName) => {
  const response = await client2.get(apiUrl, { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// Custom hook for making requests
export default function useRequest(request, { initialData, ...config } = {}) {
  const { data: response, error, isValidating, mutate: originalMutate } = useSWR(
    request && JSON.stringify(request),
    () => client2(request || {}),
    {
      ...config,
      suspense: false,
      revalidateOnFocus: false,
      revalidateIfStale: true,
      revalidateOnMount: true,
      initialData: initialData && {
        status: 200,
        statusText: 'InitialData',
        headers: {},
        data: initialData,
      },
    }
  );

  return {
    data: response?.data, // Safe access to response data
    response,
    error,
    isValidating,
    mutate: update => originalMutate(response => ({
      ...response,
      data: { ...response.data, ...update }, // Merge updated data
    })),
  };
}

// Exporting client2 and other functions
export { client2, uploadMedia, downloadBlobFile };
