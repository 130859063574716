import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import { Col, Divider, Row } from 'antd'
import Chart from 'react-apexcharts';
import Notifications from '../../components/Notifications';
import { client2 } from '../../operationalRequest';
import moment from 'moment';
import WeekReport from './WeekReport';
import BatteryReport from './BatteryReport';
import PvReport from './PvReport';
import LoadReport from './LoadReport';
import PieChart from './PieChart';
import Input from 'antd/es/input/Input';

const Dashboard2 = () => {
    const [report, setReport] = useState({} as any);
    const [cent, setCent] = useState(1 as any);

    const fetchData = async () => {
        try {
            const response = await client2.get('api/dashboard/report2');
            setReport(response?.data?.data);

        } catch (e) {
            console.error('Error fetching data:', e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Layout title="Dashboard">
            <Row className='flex justify-between border shadow-sm rounded mt-2 py-4 px-4' gutter={[16, 16]}>
                <Col md={4}>
                    <span>Yesterday's Consumption</span>
                    <div className='flex space-x-1 mt-2'>
                        <h5 className="font-semibold text-lg">{report?.yesterdayConsumption?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0} kWh</h5>
                        <small className={`${report?.yesterdayConsumptionIncrement < 0 ? 'bg-red-200' : 'bg-green-200'} px-1 my-auto`}>{report?.yesterdayConsumptionIncrement?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0}%</small>
                    </div>
                    <small>Compare to day before yesterday's consumptions</small>
                </Col>
                <Divider className='h-20 my-auto' type="vertical" />
                <Col md={4}>
                    <span>Yesterday's Production</span>
                    <div className='flex space-x-1 mt-2'>
                        <h5 className="font-semibold text-lg">{report?.yesterdayProduction?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0} kWh</h5>
                        <small className={`${report?.yesterdayProductionIncrement < 0 ? 'bg-red-200' : 'bg-green-200'} px-1 my-auto`}>{report?.yesterdayProductionIncrement?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0}%</small>
                    </div>
                    <small>{'00:00'} - 23:59 </small>
                </Col>
                <Divider className='h-20 my-auto' type="vertical" />
                <Col md={4}>
                    <span>Total Cost This Month</span>
                    <div className='flex flex-col space-x-1 mt-2'>
                        <div className='flex justify-start space-x-3'>
                            <Input defaultValue={1} onChange={(e: any) => setCent(e.target.value)} type="number" /> <h4 className='mt-1' >ct</h4>
                        </div>
                        <h5 className="font-semibold text-lg">{ (parseFloat(cent) * parseFloat(report?.gridNegative))?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0} ct</h5>
                    </div>
                </Col>
                <Divider className='h-20 my-auto' type="vertical" />
                <Col md={4}>
                    <span>Efficiency Rating</span>
                    <div className='flex space-x-1 mt-2'>
                        <h5 className="font-semibold text-lg">42% kWh</h5>
                        <small className='bg-green-100 px-1 my-auto'>+2.19%</small>
                    </div>
                    <small>Compare to last month</small>
                </Col>
            </Row>
            <Row className='mt-5 flex justify-between'>
                {/* <Col className='w-auto'> */}
                    <WeekReport report={report} />
                {/* </Col> */}
                {/* <Col md={12}>
                    <h1 className='text-lg font-semibold ml-10 mb-2' > Based On Yesterday's Report</h1>
                    <PieChart data={[
                        parseFloat(report?.yesterdayConsumption?.toFixed(2)) ?? 0,
                        parseFloat(report?.yesterdayProduction?.toFixed(2)) ?? 0
                    ]}/>
                </Col> */}
            </Row>
            {/* <Row >
                <Col className='w-full'>
                   <BatteryReport report={report} />
                </Col>

            </Row> */}
            <Row >
                <Col className='w-full'>
                    <PvReport/>
                </Col>

            </Row>
            <Row >
                <Col className='w-full'>
                    <LoadReport />
                </Col>

            </Row>
        </Layout>
    )
}

export default Dashboard2